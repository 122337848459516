import React from 'react'
import "./Plans.css"
import {plansData} from "../../data/plansData"
import whiteThick from "../../assets/whiteTick.png"

const Plans = () => {
  return (
    <div className='plans-container' id='plans'>
    <div className="blur plans-blur1"></div>
    <div className="blur plans-blur2"></div>
    <div className="programs-header" style={{gap: "2rem"}}>
    <span className='stroke-text'>READY TO START</span>
    <span>YOUR JOURNEY</span>
    <span className='stroke-text'>NOW WITH US</span>
    </div>
    
    <div className="plans">
    {plansData.map((plan, i)=> (
        <div className="plan" key={i}>
        {plan.icon}
        <span>{plan.name}</span>
        <span>$ {plan.price}</span>
        <div className="features">
        {plan.features.map ((feature, i) => (
            <div className="feature">
            <img src={whiteThick} alt="" />
            <span key={i}>{feature}</span>
            </div>
            ))}
        </div>
        <div><span>See more benefits →</span></div>
        <button className='btn'>JOIN NOW</button>
        </div>
        ))}
    </div>
    </div>
  )
}

export default Plans